import { useEffect, useState, useCallback, useContext } from 'react';
// REDUX IMPORTS
import { useSelector, useDispatch } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link } from 'react-router-dom';
// NPM IMPORTS
import _ from 'lodash';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// MATERIAL IMPORTS
import {
    Box, Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, tableCellClasses,
    Typography,
    Stack, Switch,
    Pagination
} from '@material-ui/core';
// import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
// COMPONENT IMPORTS
import SortingSelectingHeadScore from './sortSelectingHeadStaff';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import Scrollbar from '../../../../../components/Scrollbar';
import { PATH_DASHBOARD } from "../../../../../routes/paths";
// REDUX ACTION IMPORTS
import { getAllGroups, searchAllGroups } from '../../../../../redux/action/staffAction';
// API IMPORTS
import { grpDraftListApi } from "../../../../../_apis_/authApi.js/empapi";
import { SettingsContext } from "../../../../../contexts/SettingsContext";
import PaginationTable from '../../../../Common Component/PaginationTable';
import SearchBox from '../../../../Common Component/SearchBox';




// ----------------------------------------------------------------------



const TABLE_HEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'ID'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name'
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description'
    },
    {
        id: 'location',
        numeric: false,
        disablePadding: false,
        label: 'Location'
    },

];

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {


        width: 210,
        height: 45,


        '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },


        '& .MuiOutlinedInput-input::placeholder': {
            color: theme.palette.text.buttonText, // Change this color to your desired placeholder color,
            fontFamily: 'Nunito, sans-serif'
        }
    },
    [theme.breakpoints.down('sm')]: {


        width: 150,
        height: 35,


        '&.Mui-focused': { width: 150, boxShadow: theme.customShadows.z8 },

        '& .MuiOutlinedInput-input::placeholder': {
            fontSize: '12px',
            color: theme.palette.text.buttonText, // Change this color to your desired placeholder color,
            fontFamily: 'Nunito, sans-serif'
        }
    },
    color: '#5F6368 !important',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
        border: 'none !important',
        color: '#5F6368 !important', fontWeight: '100 !important'
    },
}));

const useStyles = makeStyles({
    pagination: {
        '& .MuiTablePagination-actions > button:first-of-type': {
            display: 'none', // Hide the "Previous" button
        },
        '& .MuiTablePagination-actions > button:last-of-type': {
            display: 'none', // Hide the "Next" button
        },
        '& .MuiTablePagination-toolbar': {
            paddingLeft: 1,
        },
    },
    paginationText: {
        color: '#212668', // Change this to the color you want,
        fontWeight: '600 !important'
    }
});


export default function ScoreGroupTable() {


    const classes = useStyles();


    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // CONTEXT STATES
    const { setColor, themeMode } = useContext(SettingsContext);
    // REDUX STATES
    const dispatch = useDispatch();
    const group = useSelector(state => state.groups);
    // REACT STATES
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [draftInfo, setDraftInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const [tableOrder, setTableOrder] = useState(false);
    const [pageRange, setPageRange] = useState(0)

    // get Groups
    useEffect(() => {
        setIsLoading(true);
        dispatch(getAllGroups(1, true))
        if (!loggedUserData?.staff) {
            getDraftData();
        }
    }, [])


    // chck error connection

    const getDraftData = async () => {
        const { data } = await grpDraftListApi(1, 20);
        setDraftInfo(data?.results);
    }

    const handleTimeOut = () => {

        if (isLoading && data?.length === 0) {
            setIsLoading(false);
        }

    }

    setTimeout(() => {
        handleTimeOut();
    }, 60000);




    // updating DATA STATE

    useEffect(() => {

        if (group?.results !== undefined) {
            setIsLoading(false);
            setData(group?.results);
            setCount(group?.count)
            setPageRange(group?.page_range?.length);
        }
    }, [group])


    const handleChangePage = (event, newPage) => {
        setData([]);
        setCount(0)
        setIsLoading(true);
        if (filterName && filterName !== "") {
            dispatch(searchAllGroups(event.target.value, newPage))
            return setPage(newPage);
        }
        dispatch(getAllGroups(newPage, true));
        return setPage(newPage);
    };

    // const handleDisplayRowText = ({ from, to, count }) => {
    //     return <Box sx={{ display: 'flex' }}>
    //         <Typography sx={{ color: '#212668', fontWeight: 700 }}>{from} – {to}</Typography>
    //         <Typography>&nbsp;of&nbsp;</Typography>
    //         <Typography sx={{ color: '#212668', fontWeight: 700 }}>{count !== -1 ? count : `more than ${to}`}</Typography>

    //     </Box>
    // }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    const sendQuery = (queryText) => {
        dispatch(getAllGroups(1, true, queryText || ""))
    }

    const delayedQuery = useCallback(_.debounce((query) => sendQuery(query), 1000), []);

    const handleFilterByName = (event) => {
        setData([]);
        setCount(0)
        setIsLoading(true);
        setPage(0);
        setFilterName(event.target.value)
        delayedQuery(event.target.value)

    };

    const handleTableOrder = () => {
        setData(data.reverse())
        setTableOrder(!tableOrder)
    }


    return (
        <>

            <ScoreWrapper title="Select Groups" fnDraft={getDraftData} draftData={draftInfo} link={[{ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: PATH_DASHBOARD.general.scoreGrp }]}>

                <Stack sx={{ width: "100%", justifyContent: { sm: "flex-end", xs: 'flex-start' } }} direction="row" alignItems="center" alignContent="center" >

                    {/* <SearchBox
                        filterName={filterName}
                        handleFilterByName={handleFilterByName}
                        placeholder="Search Groups..."
                        sx={{ color: '#fb5d2e', fontSize: '20px' }}
                    /> */}
                    <SearchStyle
                        value={filterName}
                        onChange={handleFilterByName}
                        placeholder="Search Groups..."
                        startAdornment={
                            <>
                                <InputAdornment position="start">
                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            </>
                        }
                    />
                    {/* <Stack direction="row" alignItems="center" alignContent="center">
                        <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                        <TablePagination
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={[100]}
                            component="div"
                            count={count || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack> */}
                </Stack>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px", py: 2, height: '55vh' }}>
                        <Table size={dense ? 'small' : 'medium'} >
                            <SortingSelectingHeadScore
                                headLabel={TABLE_HEAD}
                                rowCount={data?.length}
                                tableOrder={tableOrder}
                                handleTableOrder={handleTableOrder}
                            />
                            <TableBody>
                                {data?.map((row, index) => {
                                    return (
                                        <TableRow

                                            key={row?.id}

                                            sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                },
                                            }}
                                        >
                                            <TableCell scope="row" align="center" >
                                                <Box sx={{ width: "100%" }}>
                                                    <Typography style={{ align: "left", fontSize: { xs: '12px', sm: '14px' }, marginRight: '5px' }}>{row.id}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align='center' scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Link
                                                        to={`${PATH_DASHBOARD.general.score.selectEmployee}?id=${row.id}`}
                                                        style={{ color: '#626262', cursor: 'pointer', textDecoration: 'underline', fontSize: '1rem' }}
                                                    >

                                                        {/* <Button disableRipple variant='text' sx={{ justifyContent: "flex-start", color: themeMode === 'light' ? setColor?.main : setColor?.dark, p: 0, m: 0, "&:hover": { bgcolor: "transparent" }, fontSize: "14px" }}>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }} noWrap> */}
                                                        <Typography sx={{ fontSize: { xs: '12px', sm: '14px' }, fontWeight: 'bold' }} noWrap>
                                                            {row.name}
                                                        </Typography>

                                                        {/* </Typography>
                                                        </Button> */}
                                                    </Link>
                                                </Box>
                                            </TableCell>

                                            <TableCell align="center" scope="row" padding="normal">
                                                <Typography sx={{ fontSize: { xs: '12px', sm: '14px' } }} noWrap>
                                                    {row.description}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">

                                                <Box sx={{ width: "100%" }}>
                                                    <Typography sx={{ fontSize: { xs: '12px', sm: '14px' } }} noWrap>
                                                        {row.location}
                                                    </Typography>
                                                </Box>



                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                {data?.length === 0 && (
                                    <TableRow>

                                        {isLoading ? <TableCell colSpan={12}>
                                            <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ padding: '10%' }}>
                                                <CircularProgress /> Loading Groups...
                                            </Box>
                                        </TableCell> : <TableCell colSpan={12}>
                                            <Typography gutterBottom align="center" variant="subtitle1" sx={{ padding: '10%' }}>
                                                No Groups Found
                                            </Typography>
                                        </TableCell>}

                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
                {/* @description 
                   showPage-. to show child page
                   cPage -> childPage count
                   page -> page count
                     */}
                <PaginationTable
                    count={count || 0}
                    tablePage={(page - 1)}
                    // tablePage={showPage ? cPage - 1 : page - 1} // for tablepagination component
                    pageRange={pageRange}
                    // page={showPage ? cPage : page} // for pagination component
                    page={page}
                    handlePagination={handleChangePage}
                    style={{ml: 'auto', color: '#212668',fontWeight: 700 }}
                />
                {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <>
                        <Typography variant="body2" sx={{ fontSize: '16px', paddingLeft: '35px' }}>
                            Showing
                        </Typography>
                        <TablePagination
                            classes={{ root: classes.pagination, displayedRows: classes.paginationText, }}
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={[100]}
                            component="div"
                            count={count || 0}
                            rowsPerPage={rowsPerPage}
                            page={(page - 1)}
                        // onPageChange={handleChangePage}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                    <Pagination
                        sx={{ ml: 'auto' }}
                        count={pageRange}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                    />
                </Box> */}
            </ScoreWrapper>
        </>
    );
}
