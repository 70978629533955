import React from "react";
import ReactApexChart from 'react-apexcharts';
import { styled, useTheme } from '@material-ui/core/styles';


export const ReactBarChart = (props) => {
    const theme = useTheme();
    const GRAPH_COLOR = theme.palette.graphColors.graphColor;
    const {
        options, series,
        refrence, id, color,
        chartEvents, tickAmount,
        title, height, width,

        xAxisLabelFormatter,
        xCategories, xTitle,
        xAxisLabelShow = true,

        yAxisLabelShow = true,
        yTitle,

        legendPosition = 'top',
        legendShow,
        datalableEnabled = false,
        plotOptionsDistributed,

        handleTooltipCustomFun,
        barChartLegendsEvent,
        plotOptionsHorizontal,
    } = props
    console.log('series', series)
    const barChartOption = {
        // colors: ['#3CB8E1', '#FFCB4E', '#FF935A'],
        colors: GRAPH_COLOR,
        // series: series,
        chart: {
            id: `${id}`,
            height: 550,
            type: 'bar',
            toolbar: {
                show: false
            },
            events: {
                dataPointSelection: (event, chartContext, config) => { chartEvents(event, chartContext, config) },
                // legendClick: (chartContext, seriesIndex, config) => { barChartLegendsEvent(chartContext, seriesIndex, config) }
                // dataPointSelection: (event, chartContext, config) => {

                //     const numberPattern = /\d+/g;
                //     const noSpecialChars = percentaegRange[config.dataPointIndex].match(numberPattern);
                //     console.log(".......===>", distStaffIds, noSpecialChars, config);
                //     if (noSpecialChars?.length > 1) {
                //         navigate(`/analytics/StaffTrendReportResultDetails?staff_Id=${distStaffIds[config.dataPointIndex] || ''}&score_card=${selAnaFilters?.scoreCard}&group_id=${selAnaFilters?.group}&team_id=${selAnaFilters?.teams}&from_percentage=${noSpecialChars[0]}&to_percentage=${noSpecialChars[1]}&is_distribution_graph=${'True'}&from_date=${selAnaFilters?.fromDate}&to_date=${selAnaFilters?.toDate}&flag=${timeBtnGroupStaTrend === 'quarter' ? 'year' : timeBtnGroupStaTrend}&event_type=${selAnaFilters?.eventType}&event_sub_type=${selAnaFilters?.eventTypeEventSubType}&supervisor_id=${selAnaFilters?.supervisor}&scorecard_category=${selAnaFilters?.scoreCardCategory}&evaluator_id=${selAnaFilters?.evaluator}&highlight_value=${selAnaFilters?.highlightValue}&filter_by=${selAnaFilters?.filterBy}&module="1"&sub_module="1.1"&filter_by_range=${selAnaFilters?.filterByRange}&from_na=${'False'}&from_table=${'False'}&data_tag=${selAnaFilters?.dataTag || ""}&data_tags_operator=${selAnaFilters?.dataTagsOperator || ""}`);
                //     } else {
                //         navigate(`/analytics/StaffTrendReportResultDetails?staff_Id=${distStaffIds[config.dataPointIndex] || ''}&score_card=${selAnaFilters?.scoreCard}&group_id=${selAnaFilters?.group}&team_id=${selAnaFilters?.teams}&from_percentage=${0}&to_percentage=${5}&is_distribution_graph=${'True'}&from_date=${selAnaFilters?.fromDate}&to_date=${selAnaFilters?.toDate}&flag=${timeBtnGroupStaTrend === 'quarter' ? 'year' : timeBtnGroupStaTrend}&event_type=${selAnaFilters?.eventType}&event_sub_type=${selAnaFilters?.eventTypeEventSubType}&supervisor_id=${selAnaFilters?.supervisor}&scorecard_category=${selAnaFilters?.scoreCardCategory}&evaluator_id=${selAnaFilters?.evaluator}&highlight_value=${selAnaFilters?.highlightValue}&filter_by=${selAnaFilters?.filterBy}&module="1"&sub_module="1.1"&filter_by_range=${selAnaFilters?.filterByRange}&from_na=${'True'}&from_table=${'False'}&data_tag=${selAnaFilters?.dataTag || ""}&data_tags_operator=${selAnaFilters?.dataTagsOperator || ""}`);
                //     }
                // },
                // legendClick: (chartContext, seriesIndex, config) => {
                //     console.log(chartContext, seriesIndex, config)
                //     if (customGraphData && module === '4.7') {
                //         const seriesArr = config?.globals?.series[0];
                //         if (seriesArr[seriesIndex] !== null) {
                //             const tempArr = seriesArr?.map((row, index) => { if (index === seriesIndex) { return null } return row })
                //             return setSeriesData([{ name: 'Count', data: tempArr }])
                //         }
                //         if (seriesArr[seriesIndex] === null) {
                //             const tempArr = seriesArr;
                //             tempArr[seriesIndex] = customGraphData[0]?.data[seriesIndex];
                //             console.log(tempArr);
                //             setSeriesData([{ name: 'Count', data: tempArr }])
                //         }

                //     }

                // }
            }
        },
        // colors,
        plotOptions: {
            bar: {
                columnWidth: '35%',
                distributed: plotOptionsDistributed,
                horizontal: plotOptionsHorizontal,
                // borderRadius: 4,
                // horizontal: true,
                // dataLabels: {
                //     position: 'top',
                // },
                // borderRadius: 10,
                // borderRadiusApplication: 'end', // 'around', 'end'
                // borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    position: 'top',
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: datalableEnabled,
            // formatter: (val, opts) => {
            //     return `${val} %`
            // },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        legend: {
            show: legendShow,
            position: legendPosition,
            horizontalAlign: 'right',
            offsetY: -25,
            labels: {
                colors: undefined
                // colors: GRAPH_COLOR
            },
            onItemClick: {
                toggleDataSeries: true
            },
            showForSingleSeries: true,
            offsetX: 40,
        },
        xaxis: {
            categories: xCategories,
            labels: {
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: false,
                trim: true,
                // minHeight: undefined,
                // maxHeight: 260,
                style: {
                    // colors,
                    fontSize: '12px'
                },
                show: xAxisLabelShow,
                formatter: (val) => xAxisLabelFormatter(val)
            },
            title: {
                text: xTitle,
                offsetY: 80,

            },
            // tickPlacement: 'between',
            axisBorder: {
                show: true,
                color: "#e0e0e0",
            },

        },
        yaxis: {
            title: {
                text: yTitle,
                offsetX: -2,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            axisBorder: {
                show: true,
                color: "#e0e0e0",
            },
            floating: false,
            labels: {
                show: yAxisLabelShow,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: "#000",
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 10,
                offsetY: 10,
                rotate: 0,
            },
            min: 0,
            max: 100,
            // tickAmount: 5,
            // stepSize: 20
            // tickAmount: tickAmount 
        },
        tooltip: {
            // x: {
            //     formatter: (val) => tooltipXFormatter(val)
            // },
            // y: {
            //     formatter: (val) => tooltipYFormatter(val)
            // },
            shared: false,
            intersect: true,
            custom: ({ series, seriesIndex, dataPointIndex, w }) => handleTooltipCustomFun(series, seriesIndex, dataPointIndex, w)
            // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            //     var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            //     return `<ul style="font-size: 12px;padding:5px;text-align: left; "> 
            //   <li><span>${timeBtnGroup === "quarter" ? "Quarter" : timeBtnGroup} ${w.globals.labels[dataPointIndex]}:</span> <span>${data}%</span>  </li>
            //   </ul>`;
            // }
        },
        fill: {
            opacity: 1
        },
        title: {
            text: title,
            align: 'middle',
            margin: 30,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: 'black'
            },
        },
        markers: {
            size: 0,
            colors: undefined,
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        grid: {
            show: true,
            borderColor: '#f1f1f1',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            row: {
                colors: undefined,
                opacity: 0.5
            },
            column: {
                colors: undefined,
                opacity: 0.5
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },

        // theme: {
        //     mode: 'light',
        //     palette: theme.ApexCharts.theme.palette
        //     palette: 'palette3',
        //     monochrome: {
        //         enabled: false,
        //         color: '#255aee',
        //         shadeTo: 'light',
        //         shadeIntensity: 0.65
        //     },
        // }
        noData: {
            text: 'No Data Found',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '14px',
                fontFamily: undefined
            }
        },
        responsive: [
            {
                breakpoint: 425,
                options: {
                    noData: {
                        text: 'No data found...',
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 20,
                        offsetY: 0,
                        style: {
                            // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                            fontSize: '10px',
                            fontWeight: "bold"
                        }
                    },
                },
            },
            {
                breakpoint: 1024,
                options: {
                    noData: {
                        text: 'No data found for the data Range Selected.',
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 20,
                        offsetY: 0,
                        style: {
                            // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                            fontSize: '12px',
                            fontWeight: "bold"
                        }
                    },
                },
            }
        ],

    }
    console.log('refrence', refrence)
    return <>
        <ReactApexChart
            ref={refrence}
            type="bar"
            series={series}
            options={barChartOption}
            width={width}
            height={height}
        />
    </>
}

export const ReactPieDounoughtChart = (props) => {
    const { options, series,
        refrence, id, color,
        chartEvents, tickAmount,
        titleMargin = 0,
        title, height, width,
        labelData, titleAlign = 'left',
        xAxisLabelFormatter,
        xCategories, xTitle,
        xAxisLabelShow = true,

        yAxisLabelShow = true,
        yTitle,


        legendShow, legendPosition = 'bottom',
        datalableEnabled,
        plotOptionsDistributed,
        plotOptionsPieDonutLabelTotal,
        plotOptionsPieDonutLabel,
        plotOptionsPieDonutLabelName,

        handleTooltipCustomFun,
        tooltipYFormatter,
        barChartLegendsEvent,
        plotOptionsHorizontal,
        chartType

    } = props
    const theme = useTheme();
    const GRAPH_COLOR = theme.palette.graphColors.graphColor;
    console.log('labelData', labelData, series)
    const optionPie = {
        colors: GRAPH_COLOR,
        labels: labelData,
        chart: {
            type: chartType,
            // height: 550,

        },
        title: {
            text: title,
            align: titleAlign,
            margin: titleMargin,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: 'black'
            },
        },

        legend: {
            position: legendPosition,
            fontSize: '15px',
            showForSingleSeries: true,
            // offsetX: -20,
            // offsetY: 64,
            // itemMargin: { vertical: 8 }
            // formatter: (val, opts) => {
            //     console.log('val', val, opts)
            //     return 'series'
            //     // return val + " - " + opts.w.globals.series[opts.seriesIndex]
            // }
            markers: {
                size: 20,
                shape: 'square', // circle, square, line, plus, cross
                fontSize: '50px',
                // strokeWidth: 10,
                // fillColors: undefined,
                radius: 2,
                // onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
        },
        plotOptions: {
            pie: {
                startAngle: 360,
                endAngle: 0,
                donut: {
                    labels: {
                        show: plotOptionsPieDonutLabel,
                        name: {
                            show: plotOptionsPieDonutLabelName,
                            fontSize: '22px',
                            fontFamily: 'Rubik',
                            color: '#212668',
                            offsetY: -10
                        },
                        total: {
                            show: plotOptionsPieDonutLabelTotal,
                            label: 'Total Usage',
                            color: '#212668',
                            fontWeight: '700',
                            fontSize: { lg: '20px', xs: '8px' }
                        }
                    }
                }
            },

        },
        dataLabels: {
            enabled: datalableEnabled,
            // formatter: (val) => {
            //     return val
            // },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val) => tooltipYFormatter(val)
                // formatter: (val) =>
                //     `Group: ${val}`

            },
        },
        fill: {
            opacity: 1,
            type: 'gredient'
        },
        noData: {
            text: 'No data found for the data Range Selected.',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 20,
            offsetY: 0,
            style: {
                // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                fontSize: '18px',
                fontWeight: "bold"
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: 'bottom'
                },
                noData: {
                    text: 'No data found.',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 20,
                    offsetY: 0,
                    style: {
                        // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                        fontSize: '12px',
                        fontWeight: "bold"
                    }
                },
            }
        }]


    };
    return <>
        <ReactApexChart
            ref={refrence}
            type={chartType}
            series={series}
            options={optionPie}
            width={width}
            height={height}
        />
    </>
}

export const ReactLineChart = (props) => {
    const theme = useTheme();
    const GRAPH_COLOR = theme.palette.graphColors.graphColor;
    const {
        refrence, id,
        chartEvents,
        color, xAxisLabelFormatter,
        series, datalableEnabled,
        options, plotOptionsHorizontal,
        width, xAxisLabelShow = true,
        height, tickAmount,
        title, xCategories,
        xTitle, yTitle,
        yTickAmount,
        legendShow,
        plotOptionsDistributed,
        handleTooltipCustomFun,
        legendPosition
    } = props
    const lineChartOptions = {
        // colors: [BAR_GRAPH_COLOR,BAR_GRAPH_COLOR_LG,BAR_GRAPH_COLOR_LR,BAR_GRAPH_COLOR_V],
        colors: GRAPH_COLOR,
        chart: {
            height: 350,
            type: 'line',
            events: {
                dataPointSelection: (event, chartContext, config) => { chartEvents(event, chartContext, config) }
                // dataPointSelection: (event, chartContext, config) => {
                //     handleRedirectFromChart(timeRangeRedirect[config?.dataPointIndex], yearRange[config?.dataPointIndex])
                // },
                // markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
                //     return navigate(`/analytics/scorecards/score_card_result_details?from_date=${moment(searchParams?.get('fromDate')).format('YYYY-MM-DD')}&to_date=${moment(searchParams?.get('toDate')).format('YYYY-MM-DD')}&filterBy=${searchParams?.get('filterBy')}&score_Card_Id=${searchParams?.get('scoreCard')}&event_type=${searchParams.get('eventType') || ''}&event_type_id=${eventTypeTrendApiRes[seriesIndex]?.id}&sub_type=${searchParams?.get('eventTypeEventSubType')}&quarter=${handleQuarter(seriesIndex, dataPointIndex)}&month=${handleMonth(seriesIndex, dataPointIndex)}&week=${handleWeek(seriesIndex, dataPointIndex)}&year=${eventTypeTrendApiRes[seriesIndex]?.groupBySeason[dataPointIndex]?.year}&group=${searchParams?.get('group')}&teams=${searchParams?.get('teams')}&module=${4}&sub_module=${4.2}&type=${'eventType'}&filterByRange=${searchParams?.get('filterByRange')}&dataTag=${searchParams?.get('dataTag') || selAnaScoreCardFilters?.dataTag || ''}&dataTagsOperator=${searchParams?.get('dataTagsOperator') || selAnaScoreCardFilters?.dataTagsOperator || ''}&sbVal=4.2`)
                // }
            },
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: plotOptionsDistributed,
                // distributed: true,
            }
        },
        dataLabels: {
            enabled: datalableEnabled
        },
        legend: {
            show: legendShow,
            position: legendPosition,
            horizontalAlign: 'right',
            offsetY: -25,
            labels: {
                colors: undefined
                // colors: GRAPH_COLOR
            },
            onItemClick: {
                toggleDataSeries: true
            },
            showForSingleSeries: true,
            offsetX: 40,
        },
        title: {
            text: title,
            align: 'middle',
            margin: 30,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: 'black'
            },
        },
        xaxis: {
            categories: xCategories,
            labels: {
                style: {
                    fontSize: '12px'
                }
            },
            title: {
                text: xTitle,
                offsetY: 80,
                style: {
                    fontSize: '12px',
                    fontWeight: '400',
                },
            },
            axisBorder: {
                show: true,
                color: "#e0e0e0",
            },
        },
        yaxis: {
            title: {
                text: yTitle,
            },
            min: 0,
            max: 100,
            axisBorder: {
                show: true,
                color: "#e0e0e0",
            },
            tickAmount: yTickAmount,

        },
        fill: {
            opacity: 1
        },
        stroke: {
            show: true,
            width: 2
        },
        tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => handleTooltipCustomFun(series, seriesIndex, dataPointIndex, w),

            // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            //     var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            //     return `<ul style="font-size: 12px;padding:5px;text-align: left; ">
            //   <li><span>${timeBtnGroup === "quarter" ? "Quarter" : timeBtnGroup} ${w.globals.categoryLabels[dataPointIndex]}:</span> <span>${data}%</span>  </li>
            //   </ul>`;
            // },
            shared: false,
            intersect: true,
        },
        markers: {
            size: 5
        }
    }
    return <>
        <ReactApexChart
            type="line"
            ref={refrence}
            series={series}
            options={lineChartOptions}
            height={364}
        />
    </>
}

export const ReactStackedChart = (props) => {
    const theme = useTheme();
    const GRAPH_COLOR = theme.palette.graphColors.graphColor;
    const {
        refrence, id,
        chartEvents,
        color, xAxisLabelFormatter,
        series, datalableEnabled,
        options, plotOptionsHorizontal,
        chartWidth, xAxisLabelShow = true,
        chartHeight, tickAmount,
        title, xCategories,
        xTitle, yTitle, legendShow,
        legendPosition,
        plotOptionsDistributed,
        handleTooltipCustomFun
    } = props
    const optionStacked = {

        // colors: ['#eb4d4b', '#ffcb4e'],
        colors: GRAPH_COLOR,
        chart: {
            height: 350,
            stacked: true,
            type: 'bar',

            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                horizontal: plotOptionsHorizontal,
                columnWidth: "15%",
                borderRadius: 5,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        dataLabels: {
            enabled: datalableEnabled,

        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            show: true,
            categories: xCategories,
            title: {
                text: xTitle,
                offsetY: 88,
                style: {
                    fontSize: "12px"
                }
            },
            axisBorder: {
                show: true,
                color: "#e0e0e0",
            },
            // labels: {
            //     style: {
            //         colors: themeMode === 'light' ? '#666666' : '#ffffff',

            //     },
            // }
        },
        yaxis: {
            // max:100,
            // tickAmount: 4,
            show: true,
            title: {
                text: yTitle,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                },

            },
            axisBorder: {
                show: true,
                color: "#e0e0e0"
            },
            labels: {
                formatter: (val) =>
                    Math.round(val)

            },
        },
        fill: {
            opacity: 1
        },
        noData: {
            text: "No data found for the data range selected.",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '18px',
                fontWeight: "bold"
            }
        },
        legend: {
            showForSingleSeries: true,
            fontSize: '12px',
            position: legendPosition,
            horizontalAlign: "right",
            showForNullSeries: true,
            showForZeroSeries: true,
            // labels: {
            //     colors: themeMode === 'light' ? ['#666666'] : ['#ffffff']
            // },
            height: 60
        },

        // tooltip: {
        //     custom: ({ series, seriesIndex, dataPointIndex, w }) =>
        //     (

        //     )
        // },
        title: {
            text: '',
            align: 'middle',
            margin: 0,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: 'black'
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 280,
                    height: 280,
                },
                legend: {
                    position: 'bottom'
                },
                noData: {
                    text: 'No data found.',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 20,
                    offsetY: 0,
                    style: {
                        // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                        fontSize: '12px',
                        fontWeight: "bold"
                    }
                },
            }
        }]
    };
    return <>
        <ReactApexChart
            type="bar"
            ref={refrence}
            series={series}
            options={optionStacked}
            height={chartHeight}
            width={chartWidth}
        />
    </>
}