/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI IMPORT
import { Box, Stack, Menu, MenuItem, Typography, IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

// APEX IMPORT
import Chart from 'react-apexcharts';

// OTHER IMPORTS
// import { useResizeDetector } from 'react-resize-detector';
// import { useNavigate } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ReactToPrint from 'react-to-print';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
// import { AnalyticContext } from 'src/contexts/AnalyticContext';
import { CollapseDrawerContext } from '../../../../../contexts/CollapseDrawerContext';
import { ReactBarChart } from '../../../../Common Component/ReactChart';

// CONTEXT IMPORTS
QuestionByStaffGraph.propTypes = {
  perGraphData: PropTypes.array,
  graphQuestionArray: PropTypes.array,
  graphQuestionIdArray: PropTypes.array,
  handleRedirect: PropTypes.func,
  hght: PropTypes.number,
  wdth: PropTypes.number,
};

export default function QuestionByStaffGraph({ perGraphData, graphQuestionArray, graphQuestionIdArray, handleRedirect, wdth, hght }) {
  // STATES
  const handle = useFullScreenHandle();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const BAR_GRAPH_COLOR = theme.palette.graphColors.graphColor;

  const { collapseClick } = React.useContext(CollapseDrawerContext);

  const componentRef = React.useRef();
  const componentRef1 = React.useRef();

  // RESIZE STATES
  const [graphWidth, setGraphWidth] = React.useState(0);
  const [graphHeight, setGraphHeight] = React.useState(450);

  React.useEffect(() => {
    if (handleRedirect) {
      let x = 0;
      if (collapseClick) {
        x = 150;
      } else {
        x = 80;
      }

      if (componentRef.current) {
        setGraphWidth(componentRef.current.offsetWidth - x);
      } else {
        setGraphWidth(0);
      }
    }
    if (!handleRedirect) {
      setGraphWidth(wdth);
      setGraphHeight(hght);
    }
  }, [componentRef, collapseClick, hght, wdth]);

  const option = {
    series: perGraphData,
    options: {
      colors: BAR_GRAPH_COLOR,
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            console.log('configPoint', config.w.config.xaxis.categories[config.dataPointIndex]);
            const tempIndex = config.w.config.xaxis.categories[config.dataPointIndex] - 1;
            const tempQuestionId = graphQuestionIdArray[tempIndex];
            if (handleRedirect) {
              handleRedirect(tempQuestionId);
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: graphQuestionArray,
        labels: {
          show: true,
          formatter: (val) => {
            return `Q ${val}`;
          }
        }
      },
      yaxis: {
        title: {
          text: 'Percentage'
        },
        max: 100
      },
      tooltip: {
        x: {
          formatter: (val) => {
            return `Q ${val} | ${val} `;
          }
        },
        y: {
          formatter: (val) => {
            return `${val} % `;
          }
        },
        shared: false,
        intersect: true
      },
      fill: {
        opacity: 1
      },
      noData: {
        text: 'No data found.',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '18px',
          fontWeight: 'bold'
        }
      },
      title: {
        text: '',
        align: 'middle',
        margin: 30,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: 'black'
        }
      },
      responsive: [
        {
          breakpoint: 425,
          options: {
            noData: {
              text: 'No data found...',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 20,
              offsetY: 0,
              style: {
                // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                fontSize: '10px',
                fontWeight: "bold"
              }
            },
          },
        },
        {
          breakpoint: 1024,
          options: {
            noData: {
              text: 'No data found.',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 20,
              offsetY: 0,
              style: {
                // fontSize: {lg:'18px',md:'18px',sm:'18px',xs:"10px"},
                fontSize: '12px',
                fontWeight: "bold"
              }
            },
          },
        }
      ],
    }
  };

  const handleChartEvents = (event, chartContext, config) => {
    console.log('configPoint', config.w.config.xaxis.categories[config.dataPointIndex]);
    const tempIndex = config.w.config.xaxis.categories[config.dataPointIndex] - 1;
    const tempQuestionId = graphQuestionIdArray[tempIndex];
    if (handleRedirect) {
      handleRedirect(tempQuestionId);
    }
  }

  // const handleTooltipXFormatter = (val) => {
  //   return `Q ${val} | ${val} `;
  // }

  // const handleTooltipYFormatter = (val) => {
  //   return `${val} % `;
  // }
  const handleTooltipCustomFun = (series, seriesIndex, dataPointIndex, w) => {
    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
    return `<ul style="font-size: 12px;padding:0px;text-align: left; "> 
  <li style="background-color:#e0e0e0; padding:5px; margin:0px;"><b><span>Q${w.globals.labels[dataPointIndex]} | </span> </b> <span>${w.globals.labels[dataPointIndex]}</span>  </li>
  <li style="padding:5px; margin:0px;"><span>Percentage:</span> ${data}%</li>
  </ul>`;
  }

  const xAxisLabelFormatter = (val) => {
    return `Q ${val}`;
}
  return (
    <>
      <Box sx={{ width: '100%', cursor: 'default' }}>
        <FullScreen handle={handle}>
          <Box
            className="my-component"
            display={handle?.active ? 'flex' : 'block'}
            ref={componentRef}
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '100%',
              height: handleRedirect ? '500px' : (hght + Number(50)),
              my: 3,
              border: '1px solid #dfe6e9',
              borderRadius: 1
            }}
          >
            {(handle?.active === false && handleRedirect) && (
              <Box sx={{ position: 'absolute', top: '0' }}>
                <Stack direction="row" alignItems="center">
                  <IconButton onClick={handleClick} className="icon">
                    <MenuIcon />
                  </IconButton>
                </Stack>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  <MenuItem
                    sx={{ fontSize: '12px' }}
                    onClick={(event) => {
                      handleClose(event);
                      handle.enter();
                    }}
                  >
                    Fullscreen
                  </MenuItem>
                  <ReactToPrint
                    trigger={() => <MenuItem sx={{ fontSize: '12px' }}>Print</MenuItem>}
                    content={() => componentRef1.current}
                  />
                  <MenuItem
                    sx={{ fontSize: '12px' }}
                    onClick={(event) => {
                      handleClose(event);
                      exportComponentAsJPEG(componentRef, { fileName: '1.2 Question By Staff.jpeg' });
                    }}
                  >
                    Download Jpeg
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: '12px' }}
                    onClick={(event) => {
                      handleClose(event);
                      exportComponentAsPNG(componentRef, { fileName: '1.2 Question By Staff.png' });
                    }}
                  >
                    Download png
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: '12px' }}
                    onClick={(event) => {
                      handleClose(event);
                      exportComponentAsPDF(componentRef1, {
                        fileName: '1.2 Question By Staff.pdf',
                        pdfOptions: { w: 450, h: 300, x: 50, y: 0, unit: 'px' }
                      });
                    }}
                  >
                    Download pdf
                  </MenuItem>
                </Menu>
              </Box>
            )}

            <Box
              sx={{
                my: 2,
                p: 1,
                '@media print': {
                  marginTop: '40px',
                  marginRight: '10px',
                  width: '100%',
                  display: 'block',
                  overflow: 'hidden',
                  position: 'relative'
                }
              }}
            >
              <ReactBarChart
                refrence={componentRef1}
                series={perGraphData}
                width={handle?.active ? window?.screen.width : graphWidth}
                height={handle?.active ? window?.screen.height - 250 : graphHeight}
                id='Staf'
                chartEvents={handleChartEvents}
                title=''
                xTitle=''
                yTitle='Percentage'
                legendShow={false}
                xCategories={graphQuestionArray}
                color={undefined}
                plotOptionsDistributed={false}
                handleTooltipCustomFun={handleTooltipCustomFun}
                plotOptionsHorizontal={false}
                xAxisLabelShow={!false}
                xAxisLabelFormatter={xAxisLabelFormatter}
              />
              {/* <Chart
                ref={componentRef1}
                options={option.options}
                series={option.series}
                type="bar"
                width={handle?.active ? window?.screen.width : graphWidth}
                height={handle?.active ? window?.screen.height - 250 : graphHeight}
              /> */}
            </Box>
          </Box>
        </FullScreen>
      </Box>
    </>
  );
}
