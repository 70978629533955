import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation, useSearchParams } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import {useTheme, styled } from '@material-ui/core/styles';
import {
  Box, List, Collapse, ListItemText, ListItemIcon, ListSubheader, ListItemButton
} from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import useCollapseDrawer from '../hooks/useCollapseDrawer';
// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled((props) => <ListItemButton disableRipple disableGutters {...props} />)(({ theme, collapse }) => ({
  ...theme.typography.body2,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(collapse ? 3.5 : 5),
  paddingRight: theme.spacing(2.5),
  [theme.breakpoints.up('lg')]: {
    height: 40
  },
  [theme.breakpoints.up('xl')]: {
    height: 52
  },
  color: theme.palette.text.sidebarPrimaryText,
  '&:hover': { color: theme.palette.text.buttonText, fontWeight: 'fontWeightMedium' },
  // color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    left: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.text.sidebarPrimaryText
  }
}));

const ListItemIconStyle = styled(ListItemIcon)(({ collapse }) => ({
  width: collapse ? 2 : 22,
  height: collapse ? 2 : 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object
};

function NavItem({ item, active, isShow }) {

   const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
     useCollapseDrawer();
  const [searchParams, setSearchParams] = useSearchParams();
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const theme = useTheme();
  const isActiveRoot = active(item?.path);

  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    // color: theme.palette.text.sidebarPrimaryText,
    fontWeight: 'fontWeightMedium',
    // bgColor: 'transparent',
    '&:hover': { color: theme.palette.text.buttonText },
    color: theme.palette.text.buttonText,
    bgcolor: theme.palette.action.hover,
    '&:before': { display: 'none' }
  };

  const activeSubStyle = {
    color: theme.palette.text.sidebarPrimaryText,
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          collapse={isCollapse}
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle collapse={isCollapse}>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText
                sx={{
                  fontSize: { lg: '13px', xl: '14px' },
                  color: 'inherit',
                  '&:hover': { color: theme.palette.text.sidebarSecondaryText }
                }}
                disableTypography
                primary={title}
              />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item, index) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  <ListItemStyle
                    collapse={isCollapse}
                    key={index}
                    component={RouterLink}
                    to={
                      searchParams.get('stff_emp_id')
                        ? `${path}?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=${searchParams.get(
                            'global_dash'
                          )}`
                        : path
                    }
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    <ListItemIconStyle collapse={isCollapse}>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: theme.palette.text.sidebarPrimaryText,
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: theme.palette.text.sidebarPrimaryText
                          })
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText
                      sx={{
                        fontSize: { lg: '13px', xl: '14px' },
                        color: 'inherit',
                        '&:hover': { color: (theme) => theme.palette.text.buttonText, fontWeight: 'fontWeightMedium' }
                      }}
                      disableTypography
                      primary={title}
                    />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }
  const hasChildren = (item) => {
    const { items: children } = item || {};

    if (children === undefined) {
      return false;
    }

    if (children.constructor !== Array) {
      return false;
    }

    if (children.length === 0) {
      return false;
    }

    return true;
  }
  const MultiLevel = ({ item }) => {
    const { items: children } = item || {};
    const [open, setOpen] = useState(false);
    const handleClick = () => {
      setOpen((prev) => !prev);
    };
    return (
      <>
        <ListItemStyle collapse={isCollapse}  sx={{
          ...(isActiveRoot && activeRootStyle)
        }} onClick={handleClick}>
          <ListItemIconStyle collapse={isCollapse}>{icon && icon}</ListItemIconStyle>
          {isShow && <ListItemText sx={{ fontSize: { lg: '13px', xl: '14px' }, color: 'inherit', '&:hover': { color: theme => theme.palette.text.buttonText, fontWeight: 'fontWeightMedium' } }} disableTypography primary={item.title} />}
          {isShow && <>{open ? <ExpandLessIcon sx={{ color: 'inherit', '&:hover': { color: theme => theme.palette.text.buttonText, fontWeight: 'fontWeightMedium' } }} /> : <ExpandMoreIcon sx={{ color: 'inherit', '&:hover': { color: theme => theme.palette.text.buttonText, fontWeight: 'fontWeightMedium' } }} />}</>}
        </ListItemStyle>
        <Collapse in={open} timeout="auto">
          {
            children?.map((item, i) => {
              return (
                <ListItemStyle
                  collapse={isCollapse}
                  sx={{ mb: children?.length - 1 === i ? 2 : 0 }}
                  component={RouterLink}
                  to={item?.path}
                  key={i}
                  onClick={() => {
                    onHoverLeave();
                  }}
                >
                  <ListItemIconStyle collapse={isCollapse} sx={{ margin: '10px 0px' }}>{icon && item?.icon}</ListItemIconStyle>
                  {isShow && (
                    <>
                      <ListItemText
                        sx={{
                          fontSize: { lg: '13px', xl: '14px' },
                          color: 'inherit',
                          '&:hover': { color: (theme) => theme.palette.text.buttonText, fontWeight: 'fontWeightMedium' }
                        }}
                        disableTypography
                        primary={item?.title}
                      />

                      {info && info}
                    </>
                  )}
                </ListItemStyle>
              );
            })
          }
        </Collapse>
      </>
    );
  };

  const SingleLevel = ({ item }) => {
    return (
      <ListItemStyle
        collapse={isCollapse}
        component={RouterLink}
        to={path?.split('/')[2] === 'select_scorecard' ? `${path}?empId=${loggedUserData?.staff?.id}` : path}
        sx={{
          ...(isActiveRoot && activeRootStyle)
        }}
        onClick={() => {
          onHoverLeave();
        }}
      >
        <ListItemIconStyle collapse={isCollapse} sx={{ margin: '10px 0px' }}>{icon && icon}</ListItemIconStyle>
        {isShow && (
          <>
            <ListItemText
              sx={{
                fontSize: { lg: '13px', xl: '14px' },
                color: 'inherit',
                '&:hover': { color: (theme) => theme.palette.text.buttonText, fontWeight: 'fontWeightMedium' }
              }}
              disableTypography
              primary={title}
            />
            {info && info}
          </>
        )}
      </ListItemStyle>
    );
  };

  /**
   * 
   * @param {item} 
   * @returns component with item (destructuring)
   */
  const MenuItem = ({ item }) => {
    const Component = (hasChildren(item) && loggedUserData?.staff === undefined) ?
      MultiLevel
      : SingleLevel
    return <Component item={item} />;
  };

  /**
   * @description item is an Array, which is coming from NavItem
   */
  if (!searchParams.get("stff_emp_id")) {
    return <MenuItem item={item} />;
  }
  // if (!searchParams.get("stff_emp_id")) return (
  //   <ListItemStyle
  //     component={RouterLink}
  //     to={path.split("/")[2] === "select_scorecard" ? `${path}?empId=${loggedUserData?.staff?.id}` : path}
  //     sx={{
  //       ...(isActiveRoot && activeRootStyle)
  //     }}
  //   >
  //     <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
  //     {isShow && (
  //       <>
  //         {
  //         (loggedUserData?.staff === undefined && (title === 'Dashboard')) ?
  //          {MenuItem(item)} 
  //           :
  //           <ListItemText sx={{fontSize:{lg:'13px',xl:'14px'},color:theme.palette.text.sidebarPrimaryText}} disableTypography primary={title} />
  //         }
  //         {info && info}
  //       </>
  //     )}
  //   </ListItemStyle>
  // );
  if (searchParams.get("stff_emp_id")) return (
    <>
      {
        title !== "Self-Score" && <ListItemStyle
          component={RouterLink}
          to={path?.split("/")[2] === "select_scorecard" ? `${path}?empId=${loggedUserData?.staff?.id}` : `${path}?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=${searchParams.get("global_dash")}`}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle collapse={isCollapse}>{icon && icon}</ListItemIconStyle>
          {isShow && (
            <>
              <ListItemText sx={{ fontSize: { lg: '13px', xl: '14px' }, color: 'inherit', '&:hover': { color: theme => theme.palette.text.buttonText, fontWeight: 'fontWeightMedium' } }} disableTypography primary={title} />
              {info && info}
            </>
          )}
        </ListItemStyle>
      }
    </>
  )
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  // console.log("data", loggedUserData);
  const { pathname } = useLocation();
  const match = (path) => { return (path && (path?.split("/")[2] === pathname?.split("/")[pathname?.split("/")[1] === "employee" ? 2 : 1]) ? !!matchPath({ path, end: false }, pathname) : false) };
  // const match = (path) => { console.log(path.split("/")[2], pathname.split("/")[1], path?.split("/")?.length); return (path && (path.split("/")[2] === pathname.split("/")[pathname?.split("/")[1] === "employee" ? 2 : 1]) ? !!matchPath({ path, end: false }, pathname) : false) };

  return (
    <Box {...other}>
      {navConfig.map((list, index) => {
        const { subheader, items } = list;
        return <List key={index} disablePadding>
          {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
          {items.map((item, index) => {
            if (item?.title === "Self-Score" && loggedUserData?.staff?.isSelfScore === false) {
              return null;
            }

            return (
              <NavItem key={index} item={item} active={match} isShow={isShow} />)
          })}
        </List>
      })}
    </Box>
  );
}
