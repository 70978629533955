import React from 'react';
// REDUX IMPORTS
import { useSelector, useDispatch } from 'react-redux';
// NPM IMPORTS
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
import moment from 'moment';
import _ from "lodash";

// MATERIAL IMPORTS
import ReactQuill from 'react-quill';
import {
    Box, Button,
    CircularProgress,
    Stack,
    ToggleButtonGroup, ToggleButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// COMPONENT IMPORTS
import { MIconButton } from '../../../components/@material-extend';
// CONTEXT IMPORTS
import { StaffContext } from "../../../contexts/staffContext";
// API IMPORTS
import { addGoalApi, editGoalApi, delGoalApi } from '../../../_apis_/authApi.js/staffApi';
// REDUX ACTION IMPORTS
import { newGoals, editGoals, delGoals } from '../../../redux/action/staffAction';
// CSS IMPORTS
import 'react-quill/dist/quill.snow.css';





const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        padding: "0 0 5px 0",
        margin: "5px 0 10px 0",
        "&::-webkit-scrollbar": {
            marginTop: "10px",
            width: 15,
            height: 4,
            cursor: "pointer",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
            borderRadius: 4,
            cursor: "pointer",
        },
    },

}));

GoalPanel.propTypes = {
    isEdit: PropTypes.bool
}


export default function GoalPanel({ isEdit }) {
    // REDUX STATES
    const dispatch = useDispatch();
    const goals = useSelector(state => state.goals)

    // CONTEXT STATES
    const { supervisrID, employeeID, teamId, grpId, setNewGoal, goalPanel, setGoalPanel } = React.useContext(StaffContext);

    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // REACT STATE
    const [descValue, setDescValue] = React.useState(null);
    const classes = useStyles();
    const [value, setValue] = React.useState(0)
    const [visible, setVisible] = React.useState("none")
    const [disBtn, setDisBtn] = React.useState(false);
    const [disDelBtn, setDelBtn] = React.useState(false)
    const [isSubmitted, setIsSubmitted] = React.useState(false);

    React.useEffect(() => {


        if (goals.length === 0) {

            setDescValue(null)
            formik.setFieldValue('description', null)
            setNewGoal(false);
            setGoalPanel(0);
        }
        if (goals.length > 0) {
            setNewGoal(true)
        }

        if (goalPanel === 1 || isEdit === true) {
            setValue(0);
            setDescValue(goals[0]?.description)
            formik.setFieldValue('description', goals[0]?.description)

        }

        if (goalPanel === 0 || isEdit === false) {
            setValue(0);
            setDescValue(null)
            formik.setFieldValue('description', null)
        }


    }, [goals, isEdit])




    const handleData = (index) => {
        setValue(index);
        setDescValue(goals[index]?.description)
        formik.setFieldValue('description', goals[index]?.description)

    }

    const formik = useFormik({
        initialValues: { description: null },
        validationSchema: Yup.object({}),
        onSubmit: values => {
            setIsSubmitted(true);
            const text = descValue?.replace(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)/g, '');
            if (!text) {
                return setVisible("block");
            }

            async function editGoal() {
                const newObj = {
                    "description": descValue,
                    "user": null,
                    "staff": (supervisrID || employeeID) || null,
                    "supervisor": null,
                    "group": grpId,
                    "team": teamId
                }
                setDisBtn(true)
                const { data } = await editGoalApi(newObj, goals[value].id);
                dispatch(editGoals(data));
                enqueueSnackbar('Edited Goal Successfully', {
                    variant: 'success',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                setDisBtn(false)
                setValue(0);
                setVisible("none");
            }

            if (goalPanel === 1) {
                return editGoal()
            }

            async function addGoal() {
                const newObj = {
                    "description": descValue,
                    "user": null,
                    "staff": (supervisrID || employeeID) || null,
                    "supervisor": null,
                    "group": grpId,
                    "team": teamId
                }
                setDisBtn(true)
                const { data } = await addGoalApi(newObj);
                dispatch(newGoals(data?.data));
                enqueueSnackbar('Added Goal Successfully', {
                    variant: 'success',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                setDisBtn(false)
                setValue(0);
                setDescValue(null);
                formik.setFieldValue('description', null)
                setVisible("none");
            }

            addGoal()
        }
    })

    React.useEffect(() => {
        if (_.isEmpty(formik.errors) === false && isSubmitted === true) {
            const text = formik.values.description?.replace(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)/g, '');
            if (!text) {
                setVisible("block");
            }
        }
    }, [formik?.errors])

    React.useEffect(() => {
        const text = formik.values.description?.replace(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)/g, '');
        if (!text && isSubmitted === true) {
            setVisible("block");
        }
        if (text && isSubmitted === true) {
            setVisible("none");
        }

    }, [formik.values.description])

    const handleSubmit = () => {
        const text = descValue?.replace(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)/g, '');
        if (!text) {
            return setVisible("block");
        }

        async function editGoal() {
            const newObj = {
                "description": descValue,
                "user": null,
                "staff": (supervisrID || employeeID) || null,
                "supervisor": null,
                "group": grpId,
                "team": teamId
            }
            setDisBtn(true)
            const { data } = await editGoalApi(newObj, goals[value].id);
            dispatch(editGoals(data));
            enqueueSnackbar('Edited Goal Successfully', {
                variant: 'success',
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
            setDisBtn(false)
            setValue(0);
            setVisible("none");
        }

        if (goalPanel === 1) {
            return editGoal()
        }

        async function addGoal() {
            const newObj = {
                "description": descValue,
                "user": null,
                "staff": (supervisrID || employeeID) || null,
                "supervisor": null,
                "group": grpId,
                "team": teamId
            }
            setDisBtn(true)
            const { data } = await addGoalApi(newObj);
            dispatch(newGoals(data?.data));
            enqueueSnackbar('Added Goal Successfully', {
                variant: 'success',
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
            setDisBtn(false)
            setValue(0);
            setDescValue(null);
            setVisible("none");
        }

        addGoal()
    }



    const handleDel = async () => {
        setDelBtn(true)
        const data = await delGoalApi(goals[value].id);
        if (data?.status === 204) {

            dispatch(delGoals(goals[value].id))
            enqueueSnackbar('Deleted Goal Successfully', {
                variant: 'success',
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
            setDelBtn(false)
            setValue(0);
        }
    }


    return (
        <>
            <Box>
                {
                    goalPanel === 1 && <Box className={classes.root}>
                        <ToggleButtonGroup
                            size="small"
                            value={value}

                        >


                            {goals?.map((obj, index) => (
                                <ToggleButton key={index} value={index}
                                    onClick={() => handleData(index)} aria-label="left aligned">
                                    {moment(obj.created_at).format('ddd, ll')}
                                </ToggleButton>
                            ))}

                        </ToggleButtonGroup>
                    </Box>
                }
                <ReactQuill
                    style={{ borderRadius: "25px", height: "150px", margin: "5px 0 50px 0" }}
                    theme="snow"
                    value={formik.values.description}
                    placeholder="Goal Description *"
                    // onChange={setDescValue} 
                    onChange={value => {
                        // Remove leading whitespace and empty paragraphs from HTML content
                        const typedValue = value.replace(/^(?:\s*|<p>(?:\s*|<br>|<(strong|em|u)>\s*<\/\1>)*<\/p>)+/g, '');
                        formik.setFieldValue('description', typedValue);
                        setDescValue(typedValue)
                        formik.setFieldTouched('description', true, true);
                    }}
                />
                <Typography variant="body2" sx={{ display: visible, color: "red" }}>
                    *Required field
                </Typography>
                <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }}>
                    {goalPanel === 1 && <Button disabled={disDelBtn} variant="contained" onClick={handleDel} sx={{ mx: 2 }}>delete {disDelBtn && <CircularProgress size={14} sx={{ ml: 1 }} />}</Button>}
                    <Button disabled={disBtn} variant="contained" onClick={formik.handleSubmit}>{goalPanel === 1 ? "Edit" : "add"} {disBtn && <CircularProgress size={14} sx={{ ml: 1 }} />}</Button>
                </Stack>
            </Box>
        </>
    )
}